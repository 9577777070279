<template>
  <div class="p-4">
    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
      <b-img fluid :src="image" alt="Login V2" />
    </div>
    <h2 class="text-center mt-3">Memuat Aplikasi</h2>
  </div>
</template>

<script>
import { BAlert, BLink, BImg } from "bootstrap-vue";

export default {
  components: {
    BAlert,
    BLink,
    BImg,
  },
  data() {
    return {
      accessToken: null,
      countDown: 5,
      config: {
        api: "me",
      },
      image: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  methods: {
    setToken() {
      const _ = this;
      const { accessToken, refreshToken } = _.$route.query;
      _.$store.commit("token/UPDATE_ACCESS_TOKEN", accessToken);
      _.$store.commit("token/UPDATE_REFRESH_TOKEN", refreshToken);
      _.accessToken = _.$store.getters["token/GET_ACCESS_TOKEN"];
    },
    me() {
      const _ = this;
      _.$axios.get(_.config.api).then((res) => {
        const consume = res.data.data;
        _.$store.commit("token/ACCOUNT", consume);
      });
    },
    menu() {
      const _ = this;
      _.$axios.get("/me/menus").then((res) => {
        const consume = res.data.data;
        const menus = consume.account.role.menus;
        let parentMenus = menus.filter(menu => menu.parent == 0);
        let childMenus = menus.filter(menu => menu.parent != 0);

        for (let parentIndex = 0; parentIndex < parentMenus.length; parentIndex++) {
          parentMenus[parentIndex].children = [];
          for (let childIndex = 0; childIndex < childMenus.length; childIndex++) {
            if(parentMenus[parentIndex].id == childMenus[childIndex].parent){
              parentMenus[parentIndex].children = [...parentMenus[parentIndex].children, childMenus[childIndex]];
            }
          }

          if(parentMenus[parentIndex].children.length == 0){
            delete parentMenus[parentIndex].children;
          }
        }
        
        _.$store.commit("token/MENU", menus);
        _.$store.commit("token/TREE", parentMenus);
      });
    },
    countDownTimer() {
      const _ = this;
      if (_.accessToken) {
        if (_.countDown > 0) {
          setTimeout(() => {
            _.countDown -= 1;
            _.countDownTimer();
          }, 1000);
        } else {
          window.location.href = "home";
        }
      }
    },
  },
  created() {
    const _ = this;
    _.setToken();
    _.me();
    _.menu();
    _.countDownTimer();
  },
};
</script>
